import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import ReactLoading from "react-loading";
import theme from "../styles/theme";
import styled from "styled-components";

const HomePage = lazy(() => import("../pages/Home/Home"));
//const ProjectPage = lazy(() => import("../pages/Projects"));
const ResumePage = lazy(() => import("../pages/Resume/Resume"));

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <LoadingPage>
            <ReactLoading
              type={"bars"}
              color={theme.colors.lightGray}
              height="20%"
              width="20%"
            />
          </LoadingPage>
        }
      >
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/resume" Component={ResumePage} />
          <Route path="*" Component={() => <Navigate to="/" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

const LoadingPage = styled.div`
  background-color: ${theme.colors.background};
  min-height: 100vh;
  min-width: 100vw;

  justify-content: center;
  align-items: center;
  display: flex;
`;
