import { Suspense } from "react";
import AppRoutes from "./routes";

import "./App.css";

export default function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AppRoutes />
    </Suspense>
  );
}
