/* eslint-disable import/no-anonymous-default-export */

const colors = {
  background: "#1e1e1e",

  white: "#ffffff",
  lightGray: "#cacfd6",
  gray: "#525252",
  whiteGreen: "#F3F7F6",
  lightGreen: "#51B8A0",
  green: "#517664",

  purple: "#7851b8",
};

export default { colors };
